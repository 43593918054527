@use "sass:map";
@use "colors";
@use "typography";
@import "custom";

.other-locations-container {
  background: colors.$white;
  background-color: colors.$white;
  padding: 60px 0 140px;

  @include media-breakpoint-down(md) {
    padding: 40px 36px 20px;
  }

  @include media-breakpoint-down(sm) {
    padding: 40px 12px 20px;
  }

  .container {
    margin: 0 auto;
    max-inline-size: map.get($container-max-widths, "xl");
    padding: 0;
    padding-inline: 0;
  }

  .other-locations-header {
    @include typography.subhead-semi-bold;

    color: colors.$black;
    padding-block-end: 60px;

    @include media-breakpoint-down(sm) {
      padding-block-end: 20px;
    }
  }
}
